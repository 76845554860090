import React, {useLayoutEffect, useState} from 'react';
import './App.css';
import {Snake} from "./Snake/Snake";

function App() {
    const [width, height] = useWindowSize();
    return (
        <div className="App">
            <div className="snakeContainer">
                <Snake
                    height={Math.min(height, width * 2)}
                />
            </div>
        </div>
    );
}

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

export default App;
