import React, {useState} from 'react';
import './Snake.css';
import {SnakeControls} from "./SnakeControls";
import {SnakeView} from "./SnakeView";

export const Snake = (props) => {
    const [direction, setDirection] = useState('up');
    const [prevDirection, setPrevDirection] = useState('up');
    const [paused, setPaused] = useState(false);
    if (!props.height && !props.width) return <p>Snake needs width or height.</p>;

    const height = props.height || 500;
    const width = props.height / 2;

    const halfStyle = {height: height / 2, width};
    return (
        <div
            className="snake"
            style={{height, width}}
        >
            <SnakeView
                direction={direction}
                onTick={() => setPrevDirection(direction)}
                paused={paused}
                setPaused={setPaused}
            />
            <div className="snake__controlsWrapper">
                <SnakeControls
                    style={halfStyle}
                    setDirection={setDirection}
                    direction={direction}
                    prevDirection={prevDirection}
                    paused={paused}
                    setPaused={setPaused}
                />
            </div>
        </div>
    );
}