import React, {useEffect} from 'react';
import {Arrow} from "./Arrow";

let prevDirection;
export const SnakeControls = (props) => {
    const setDir = props.setDirection
    prevDirection = props.prevDirection;
    const handleInputString = (str) => {
        let newDir;
        if (['button__up', 'w', 'ArrowUp'].includes(str) && prevDirection !== 'down') newDir = 'up';
        else if (['button__left', 'a', 'ArrowLeft'].includes(str) && prevDirection !== 'right') newDir = 'left';
        else if (['button__down', 's', 'ArrowDown'].includes(str) && prevDirection !== 'up') newDir = 'down';
        else if (['button__right', 'd', 'ArrowRight'].includes(str) && prevDirection !== 'left') newDir = 'right';
        if (newDir)
            props.setDirection(newDir);
        if (props.paused)
            props.setPaused(false);
    }

    useEffect(() => {
        const thing = str => {
            const foo = handleInputString(str);
            foo && setDir(foo);
        }
        document.addEventListener('keydown', e => thing(e.key), false)
        return () => {
            document.removeEventListener('keydown', e => thing(e.key))
        }
    }, [setDir])

    const pointerControl = e => {
        const target = e.touches
            ? document.elementFromPoint(e.touches[0].pageX, e.touches[0].pageY)
            : e.target;
        if (!target) return;
        const foo = handleInputString(target.id);
        foo && props.setDirection(foo);
    };

    const getStyle = dir => dir === props.direction ? {
        backgroundColor: 'hotpink'
    } : {};

    return (
        <div
            className="snake__controls"
            style={props.style}
            onClick={pointerControl}
            onTouchStart={pointerControl}
            onTouchMove={pointerControl}
        >
            <div style={getStyle('up')} id="button__up" className="snakeControls__button snakeControls__up"><Arrow rotation={-45}/></div>
            <div style={getStyle('right')} id="button__right" className="snakeControls__button snakeControls__right"><Arrow rotation={45}/></div>
            <div style={getStyle('left')} id="button__left" className="snakeControls__button snakeControls__left"><Arrow rotation={-135}/></div>
            <div style={getStyle('down')} id="button__down" className="snakeControls__button snakeControls__down"><Arrow rotation={135}/></div>
        </div>
    );
}