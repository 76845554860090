import React, {useEffect, useRef, useState} from 'react';

let tickInterval;

const size = 20, forLoc = callBack => {
    for (let y = 0; y < size; y++) {
        for (let x = 0; x < size; x++) {
            callBack(x, y);
        }
    }
}
export const SnakeView = (props) => {
    const [dead, setDead] = useState(false);
    const [snake, setSnake] = useState([
        {
            x: Math.floor(size / 2),
            y: Math.floor(size / 2)
        }
    ]);

    const hasSnakePart = (x, y) => !!snake.find(part => part.x === x && part.y === y);

    const getFoodLocation = () => {
        const coords = [];
        forLoc((x, y) => {
            if (!hasSnakePart(x, y)){
                coords.push([x, y]);
            }
        });
        const foodLoc = coords[Math.floor(Math.random() * coords.length)];
        return {x: foodLoc[0], y: foodLoc[1]};
    }

    const isFoodLocation = (x, y) => x === food.x && y === food.y;

    const [food, setFood] = useState(getFoodLocation());

    const newFood = () => setFood(getFoodLocation());

    const tick = () => {
        if (dead || props.paused) return;
        props.onTick();
        const dir = props.direction;
        const head = snake[snake.length - 1];
        const newLoc = {...head}
        if (dir === 'up') {
            if (newLoc.y === 0) {
                newLoc.y = size - 1;
            } else {
                newLoc.y = newLoc.y - 1;
            }
        } else if (dir === 'left') {
            if (newLoc.x === 0) {
                newLoc.x = size - 1;
            } else {
                newLoc.x = newLoc.x - 1;
            }
        } else if (dir === 'down') {
            if (newLoc.y === size - 1) {
                newLoc.y = 0;
            } else {
                newLoc.y = newLoc.y + 1;
            }
        } else if (dir === 'right') {
            if (newLoc.x === size - 1) {
                newLoc.x = 0;
            } else {
                newLoc.x = newLoc.x + 1;
            }
        } else {
            return;
        }
        if (hasSnakePart(newLoc.x, newLoc.y)) {
            setDead(true);
            clearInterval(tickInterval);
        } else if (isFoodLocation(newLoc.x, newLoc.y)) {
            newFood();
            setSnake([...snake, newLoc]);
        } else {
            snake.shift();
            setSnake([...snake, newLoc]);
        }
    }

    useInterval(tick, 300);

    const viewStyle = {
        gridTemplateColumns: `repeat(${size}, 1fr)`,
        gridTemplateRows: `repeat(${size}, 1fr)`,
    }

    const renderSquare = (x, y) => {
        const isFood = isFoodLocation(x, y);
        const hasSnake = !isFood && hasSnakePart(x, y);
        return (
            <div
                key={`square-${x}-${y}`}
                className={`snakeView__square${isFood ? ' snakeView__square--food' : hasSnake ? ' snakeView__square--snake' : ''}`}
            />
        );
    }

    const renderGrid = () => {
        const squares = [];
        forLoc((x, y) => squares.push(renderSquare(x, y)))
        return squares;
    }

    return (
        <div
            className="snake__view"
            style={viewStyle}
        >
            <div
                className="snake__grid"
                style={viewStyle}
                onClick={() => props.setPaused(!props.paused)}
            >
                {renderGrid()}
            </div>
            {
                dead && (
                    <div className="snake__deadMessage">
                        <p>DEAD</p>
                    </div>
                )
            }
            <div className="snake__points snake__points--left">{snake.length}</div>
            <div className="snake__points snake__points--right">{snake.length}</div>
        </div>
    );
}

function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
